import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ru from './locales/ru.json';
import bg from './locales/bg.json';

const options = {
  order: ['localStorage'],
  lookupLocalStorage: 'language',
  caches: [],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'bg',
    detection: options,
    resources: {
      en: { translation: en },
      ru: { translation: ru },
      bg: { translation: bg },
    },
  });
