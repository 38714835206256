/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { JitNextConfig } from './types/jit-next-config.interface';

const { publicRuntimeConfig } = getConfig() as JitNextConfig;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
Sentry.init({
  dsn: publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 150,
  debug: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  enableAutoSessionTracking: true,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
});
