/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import getConfig from 'next/config';
import { ToastProvider } from 'react-toast-notifications';
import NextNProgress from 'nextjs-progressbar';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { tenantRedirectTo } from '@digital.jit/auth/utils';
import { JitNextConfig } from '../types/jit-next-config.interface';
import '../../../../../node_modules/@fullcalendar/common/main.css';
import '../../../../../node_modules/@fullcalendar/daygrid/main.css';
import '../../../../../node_modules/@fullcalendar/list/main.css';
import './_app.scss';
import '../i18n';
import {
  getSessionCookie,
  SessionContext,
  sessionCookies,
} from '../shared/context/session';
import { IJitSession } from './api/auth/jitSession.interface';
import { registerServiceWorker } from '../shared/helpers';
import api from '../api';

interface JiTPageProps {
  session?: IJitSession;
  fullUrl: string;
}

interface JitAppProps extends AppProps<JiTPageProps> {
  pageProps: JiTPageProps;
}

function SafeHydrate({ children }: any) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  );
}

const CustomApp = ({ Component, pageProps }: JitAppProps) => {
  const [session, setSession] = useState<IJitSession | null>(null);
  const { i18n } = useTranslation();
  const config = getConfig() as JitNextConfig;
  const router = useRouter();

  const resolveRedirect = useCallback((data?: any) => {
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href);
      const navigateTo = (url: string) => {
        window.location.href = url;
      };
      const nextUrl = tenantRedirectTo(currentUrl.href, config, data);
      const url = new URL(nextUrl);
      if (url.origin === currentUrl.origin && url.href !== nextUrl) {
        router.push(url.pathname, url.pathname);
      } else if (nextUrl && nextUrl !== window?.location?.href) {
        navigateTo(nextUrl);
      }
    }
  }, []);

  const fetchAndSetCurrentSession = async () => {
    try {
      const { data } = await api.tenant.getUserSessionInformation();
      if (data) {
        setSession(data);
      }
    } catch (e) {
      console.log('Error fetching session...', e.message);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const sessionValues = getSessionCookie();
    resolveRedirect(sessionValues);

    if (sessionValues?.user) {
      fetchAndSetCurrentSession();
    }
    if (window?.location?.href) {
      sessionCookies.addChangeListener(({ value }) => {
        if (value) {
          fetchAndSetCurrentSession();
          const session = typeof value === 'string' ? JSON.parse(value) : value;
          resolveRedirect(session);
        }
      });
    }

    return () => {
      sessionCookies.removeChangeListener(sessionCookies.addChangeListener);
    };
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator && typeof window !== 'undefined') {
      window.addEventListener('load', registerServiceWorker);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('load', registerServiceWorker);
      }
    };
  }, []);

  return (
    <SafeHydrate>
      <NextNProgress
        color="#556EE6"
        startPosition={0.15}
        stopDelayMs={100}
        height={5}
        showOnShallow
      />
      <Head>
        <title>JIT solution</title>
        <link rel="shortcut icon" href="/assets/images/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="/assets/images/apple-touch-icon.png"
        />
        <meta name="pinterest" content="nopin" />
        {/*
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#efefef" /> */}
      </Head>
      <main className="app">
        <ToastProvider>
          <SessionContext.Provider value={{ session, setSession }}>
            <Component {...pageProps} />
          </SessionContext.Provider>
        </ToastProvider>
      </main>
    </SafeHydrate>
  );
};

export default CustomApp;
